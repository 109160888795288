(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/new-line-to-break/assets/javascripts/new-line-to-break.js') >= 0) return;  svs.modules.push('/components/lb-ui/new-line-to-break/assets/javascripts/new-line-to-break.js');
'use strict';

setGlobal('svs.components.lbUi.newLineToBreak', function () {
  let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return text.split('\n').reduce((acc, row, index, array) => [...acc, row, index === array.length - 1 ? undefined :
  React.createElement("br", {
    key: "br-".concat(index).concat(Math.random())
  })].filter(Boolean), []);
});

 })(window);